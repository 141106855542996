<template>
    <AppFormCadastroNested
        ref="cadFonteGeradora"
        :service="service"
        :form="form"
        :disableSave="isInativo"
        backPath="/fonte-geradora"
        @onBeforeSave="onBeforeSave"
        @onAfterSave="onAfterSave"
        @onLoadDataEdit="onLoadDataEdit"
        @onValidate="onValidate"
        @onHabilitarEdicao="habilitarEdicao"
        @onCancelarEdicao="cancelarEdicao"
    >
        <template #content>
            <div class="grid formgrid">
                <div class="field col-6">
                    <label>Nome da fonte geradora</label>
                    <InputText
                        id="nome"
                        v-model.trim="form.nome"
                        minlength="2"
                        maxlength="100"
                        autocomplete="off"
                        :class="{ 'p-invalid': submitted && !form.nome }"
                    />
                    <small class="p-error" v-if="submitted && !form.nome">Nome da fonte geradora é obrigatório.</small>
                </div>
                <div class="field col-6">
                    <label>Observacao</label>
                    <InputText id="observacao" v-model.trim="form.observacao" maxlength="200" autocomplete="off" />
                </div>
            </div>
        </template>
    </AppFormCadastroNested>
</template>

<script>
import SesmtService from '../../../services/SesmtService';
export default {
    props: {
        active: Number
    },
    emits: ['desabilitarAbas', 'habilitarAbas'],
    data() {
        return {
            form: {
                active: true
            },
            isInativo: false,
            service: null
        };
    },
    created() {
        this.service = new SesmtService('/fonte-geradora');
    },
    mounted() {
        this.isInativo = !!this.$route.params.id;
        this.form.ativo = true;
    },
    methods: {
        async onLoadDataEdit(data) {
            this.form = data;
        },
        async onValidate(cbSuccess, cbError) {
            if (this.hasError()) {
                this.$toast.add({ severity: 'error', summary: 'Há campos obrigatórios não preenchidos', life: 3000 });
                return cbError();
            }

            return cbSuccess();
        },
        async onBeforeSave() {
            this.submitted = true;
        },
        async onAfterSave() {
            this.isInativo = true;
            this.submitted = false;
            this.$emit('habilitarAbas');
        },
        habilitarEdicao() {
            this.$emit('desabilitarAbas');
            this.isInativo = false;
        },
        cancelarEdicao(record) {
            this.isInativo = true;
            this.form = JSON.parse(JSON.stringify(record));
            this.$emit('habilitarAbas');
        }
    }
};
</script>

