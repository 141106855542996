<template>
    <AppPanel :location="[title]" subtitle="Preencha os campos abaixo" :hasBackButton="true" backPath="/fonte-geradora">
        <template #content>
            <div class="card">
                <div class="font-medium text-xl text-900 mb-3">
                    <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                        <AppInfoManual nomeTelaDoManual="fonte-geradora-form" />
                        <h3 class="ml-1">{{ title }}</h3>
                    </div>
                </div>
                <TabView :scrollable="true" ref="tabview" :activeIndex="active" @tab-click="onTabClick">
                    <TabPanel header="Geral" :disabled="false" :key="0">
                        <FonteGeradoraCadastro :active="active" @desabilitarAbas="desabilitarAbas" @habilitarAbas="habilitarAbas" />
                    </TabPanel>
                    <TabPanel header="Perigo/Fator de Risco" :disabled="false" :key="1">
                        <RiscoGrid :active="active" @desabilitarAbas="desabilitarAbas" @habilitarAbas="habilitarAbas" />
                    </TabPanel>
                </TabView>
            </div>
        </template>
    </AppPanel>
</template>

<script>
import { getCurrentCompany } from '@/services/store';
import RiscoGrid from './components/RiscosGrid.vue';
import FonteGeradoraCadastro from './components/FonteGeradoraCadastro.vue';
import AppInfoManual from '../../components/AppInfoManual.vue';

export default {
    data() {
        return {
            active: 0,
            form: {}
        };
    },
    components: {
        FonteGeradoraCadastro,
        RiscoGrid,
        AppInfoManual
    },
    mounted() {
        if (!this.$route.params.id) this.desabilitarAbas();
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar fonte geradora' : 'Adicionar fonte geradora';
        },
        getCurrentCompany() {
            return getCurrentCompany();
        }
    },
    watch: {
        getCurrentCompany(newValue, oldValue) {
            if (newValue !== oldValue) {
                alert('Não é possível trocar a empresa no fluxo de criação/edição de um cadastro!');
                this.$router.push({ path: 'list' });
            }
        }
    },
    methods: {
        onTabClick(event) {
            this.active = event.index;
        },
        habilitarAbas() {
            this.$refs.tabview.tabs.filter((item) => item.key != this.active).forEach((item) => (item.props.disabled = false));
            this.$refs.tabview.$forceUpdate();
        },
        desabilitarAbas() {
            this.$refs.tabview.tabs.filter((item) => item.key != this.active).forEach((item) => (item.props.disabled = true));
            this.$refs.tabview.$forceUpdate();
        }
    }
};
</script>
